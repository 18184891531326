<template>
        <v-container fluid>
        <v-row class="text-center">
            <v-col cols="3" sm="2" md="3"></v-col>
        </v-row>
        </v-container>
</template>
    
    <script>
    import { mapActions } from "vuex";
    
    export default {
        name:"Auth",
      data: () => ({
        
      }),
    
      methods: {
        ...mapActions("User", ["login"]),
        //..validate inputs
        
      },
      computed:{
        
      }
    };
    </script>
    
    <style scoped>
    </style>